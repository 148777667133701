$max-main-width: 1440px;

.main-layout{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #F4F4F4;
  display: flex;
  justify-content: center;
  .main-content{
    max-width: $max-main-width !important;
    width: 100%;
  }
}

// Wrapper responsive

@media (max-width: 2400px) {
  .main-layout{
    .main-content{
      padding: 0 2.5rem;
    }
  }
}

@media (max-width: 1920px) {
  .main-layout{
    .main-content{
      padding: 0 2.5rem;
    }
  }
}

@media (max-width: 1440px) {
  .main-layout{
    .main-content{
      padding: 0px 60px;
    }
  }
}

@media (max-width: 1280px) {
  .main-layout{
    .main-content{
      padding: 0 2.5rem;
    }
  }
}

@media (max-width: 920px) {
  .main-layout{
    .main-content{
      padding: 0 2.5rem;
    }
  }
}

@media (max-width: 600px) {
  .main-layout{
    background: #FFF;
    .main-content{
      padding: 0;
      //padding: 0 .5rem;
    }
  }
}

@media
(prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

// @media (max-width: 2400px) {
//   .main-layout{
//     .main-content{
//       max-width: $max-main-width !important;
//     }
//   }
// }
