.mb-40{
  margin-bottom: 2.5rem !important;
}

.mb-12{
  margin-bottom: .75rem !important;
}

.mb-14{
  margin-bottom: .875rem !important;
}

.bs-t-0{
  border-radius: 0 0 $pf-customer-card-border-radius $pf-customer-card-border-radius !important;
}

.border-light{
  border-color: #9FB8CC !important;
}

.text-right{
  text-align: right;
}

.text-truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-align{
  display: flex;
  justify-content: center;
  align-items: center;
}

.dollar-sign {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='7' y='13' fill='black' font-size='12' font-family='arial'>$</text></svg>");
  background-repeat: no-repeat;
  background-position: left;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
