@import "./assets/scss/modal.scss";
@import "./assets/scss/map.scss";
@import "./assets/scss/paddings.scss";
@import "./assets/jp-global/index";

@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);

@import '~react-date-range/dist/styles.css'; // main css file
@import '~react-date-range/dist/theme/default.css'; // theme css file

html,
body,
html * {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.search-input-group {
  margin-bottom: 0 !important;
}

.pf-uix-mobile-side-actions-manager-content{
  .search-input-group {
    margin-bottom: 0.8rem !important;
  }
}

.pf-uix-vehicle-list-item{
  overflow: visible !important;
}

.br-12 {
  border-radius: 12px;
}

.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.br-b-12 {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}


// Custom
.label-sibling-link{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  color: black;
}

.table-icon {
  &.inactive {
    cursor: not-allowed!important;
    opacity: 0.2;
  }
}


.pf-uix-input-group {
  label + a{
    @extend .label-sibling-link
  }
}

.mt-32 {
  margin-top: 2rem;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mt-20 {
  margin-top: 1.25rem
}

.fw500 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  padding:0px;
  margin: 0px;
}

.fw400{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding:0px;
  margin: 0px;
}

.date-picker-selector{
  z-index: 9;
}

.menu-options-pane{
  z-index: 9;
}
