$max-base-width: 392px;

.base-layout{
  display: flex;
  justify-content: center;
  .base-content{
    max-width: $max-base-width !important;
    margin-top: 5%;

    .logo {
      text-align: center;
      //max-width: 272px;
      width: 100%;
      height: auto;
      margin-bottom: 4rem !important;
      //margin: auto;
    }

    .title {
      font-style: normal;
      font-weight: 500;
    }
  }
}
