.accountinfo{
    margin-top: 80px;
    margin-left: 24px;
}

.mt-52{
    margin-top:52px
}

.accountinfo-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.accountinfo-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.accountinfo-logout{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #C50532;
    display: flex;
    justify-content: center;
}
