.pb-24{
  padding-bottom: 1.5rem;
}

.pt-24{
  padding-top: 1.5rem;
}

.p-24{
  padding: 1.5rem;
}
