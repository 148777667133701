.reset-done {
  width: 100%;
  max-width: 392px !important;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-weight: 400;
}

.reset-successfully {
  margin-top:60px
}
