.history-component {
    input {
        font-size:12px;
        max-width: 312px;
        width: 100%;
    }
    button {
        background-color: #DDDDDD!important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
    }
    .cBlack {
        color: black!important;
    }

}

.history-component-mobile{
    padding: 15px;
    padding-bottom: 75px;
    .pf-uix-vehicle-list-item{
        padding: 12px 0;
    }
}

@media (max-width: 920px) {

    .history-component {

        input {
            padding: 0.56rem 2.5rem!important;
            font-size:12px;
            //max-width: 192px!important;
            width: 100%;
        }
    }
}


