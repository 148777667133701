.inventory-side-card {
  background-color: white;
  border-radius: 12px;
  overflow-y: auto;

  .no-content{
    p{
      color: #888888;
    }
  }

  .detail-card{
    cursor: pointer;
  }
}

.inventory-side-card::-webkit-scrollbar {
  display: none;
}
