.font-6 { font-size: 0.375em !important; }
.font-8 { font-size: 0.5em !important; }
.font-9 { font-size: 0.5625em !important; }
.font-10 { font-size: 0.625em !important; }
.font-11 { font-size: 0.7em !important; }
.font-12 { font-size: 0.75em !important; }
.font-13 { font-size: 0.813em !important; }
.font-14 { font-size: 0.875em !important; }
.font-16 { font-size: 1em !important; }
.font-18 { font-size: 1.125em !important; }
.font-20 { font-size: 1.25em !important; }
.font-24 { font-size: 1.5em !important; }

.font-light { font-weight: 300; }
.font-medium { font-weight: 600; }
.font-normal { font-weight: 400; }
.font-medium-500 { font-weight: 500; }
.font-italic { font-style: italic !important;}
.font-bold { font-weight: bold !important; }


.fs-9{font-size: 0.45rem !important; }
.fs-10{font-size: 0.5rem !important; }
.fs-11{font-size: 0.55rem !important; }
.fs-12{font-size: 0.75rem !important;}
.fs-13 { font-size: 0.813rem !important; }
.fs-14{font-size: 0.875rem !important}
.fs-16{font-size: 0.8rem !important;}
.fs-18{font-size: 0.9rem !important;}
