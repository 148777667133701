.inventory-component{
}

.inventory-component-mobile{
  .table-tabs {
    justify-content: space-between;

    .table-tab-item {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

