
.marker {
  width: 80px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  top: -50px; /*positions our marker*/
  left: -50px; /*positions our marker*/
  display: block;
}

.marker-driver-tech {
  .driver-pin-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: 50px;
    left: 45px;
    z-index: 999999;
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}


.marker-driver, .marker-driver-tech {
  width: 80px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  top: -50px; /*positions our marker*/
  left: -50px; /*positions our marker*/
  display: block;
}


.pin {
  width: 20px;
  height: 20px;
  position: relative;
  top: 38px;
  left: 38px;
  background: rgba(5, 124, 255, 1);
  border: 2px solid #FFF;
  border-radius: 50%;
  z-index: 1000;
}

.pin-driver {
  width: 24px;
  height: 24px;
  position: relative;
  top: 38px;
  left: 38px;
  background: #2D82B5 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 50%;
  z-index: 1000;

  p {
    margin: 0;
  }

  &.bg-warning-map {
    background: #F0A926 !important;
    z-index: 99999999999;
  }
}

.waypoint-marker {
  width: auto;
  height: 108px;
  position: relative;
  top: 0px;
  left: 0px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 12%;
  z-index: 1000;
  background: #FFF;
}

.driver-pin-img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 45px;
  z-index: 999999;
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.pin-effect {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 8px;
  left: 8px;
  display: block;
  background: rgba(5, 124, 255, 0.6);
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2400ms ease-out infinite;
}


.marker-driver-tech:not(.marker-driver-bg) {
  .bg-info {
    background-color: #28457f !important;
    color: #fff !important;
    border-color: #28457f !important;
  }
}

.marker-driver-bg {
  .bg-info {
    //background-color: #c75f5f !important;
    background-color: #fb9999 !important;
    color: #fff !important;
    border-color: #c75f5f !important;
  }
}


.card-selected {
  .card-body {
    background-color: #CEE8F4 !important;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
