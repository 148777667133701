.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width:100%;
    overflow: hidden;
}


.canvas{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.route-body{
    overflow-x: hidden;
    overflow-y: auto;
}

.route-body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }


.log-out {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.navbar-user-logout{
    position: absolute;
    z-index: 999;
    top: 75px;
    width: 125px;
    height: 38px;
    background: #FFFFFF;
    box-shadow: 0px 1px 16px rgb(0 0 0 / 12%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    & .log-oute-text{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      padding: 0
    }
}


.sticky {
    height: 32px;
    box-sizing: content-box !important;
    max-width: 1300px;
    width: 100%;
    position: fixed;
    background-color:#f4f4f4;
    z-index: 1;
    padding: 10px;
    margin-top:0px;
}

@media (max-width: 1439px) {
    .sticky {
        max-width:calc(100% - 116px);
    }
}