/* bootstrap scss */

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../bootstrap/scss/functions";

// 2. Include any default variable overrides here
$info: #CEE8F4;
$primary: #42ACDE;
$secondary: #035B96;

$success: #74C336;
$warning: #F0A926;
$danger: #C50532;

// 3. Include remainder of required Bootstrap stylesheets
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
//@import "../bootstrap/scss/button-group";
//@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/card";
@import "../bootstrap/scss/containers";
//@import "../bootstrap/scss/forms";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/images";
//@import "../bootstrap/scss/list-group";
@import "../bootstrap/scss/reboot";
@import "../bootstrap/scss/root";
// @import "../bootstrap/scss/tables";
@import "../bootstrap/scss/type";

@import "../bootstrap/scss/bootstrap-grid";
@import "../bootstrap/scss/bootstrap-utilities";

/** end of bootstrap configuration **/

@import "variables";
@import "base";
@import "fonts";
@import "utils";
@import "form";
