.map-component {
  // width: 100%;
  //height: calc(100vh - 100px);
  // height: 100%;
    padding-top: 40px;
    // position: relative;
  // border-radius: 12px;
  //position: relative;

  .pin-driver{
    &.selected{
      width: 40px;
      height: 40px;
    }
  }

  .search-input-field{
    width: 100%;
  }

  #map {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 99;
  }

  .fab-button {
    z-index: 99;
    img {
      cursor: pointer;
    }
  }

  .search-container {
    height: calc(100vh - 9rem);
    overflow: hidden;
  }

.ditails-window-position {
  position: relative;
}
  .map-search {
    margin: 0em;
    // .search-input-field {
    //   box-shadow: 0 1px 16px rgba(0, 0, 0, 0.12);
    // }

    .search-results {
      overflow-y: scroll;
      margin-top: .75rem;
      border-radius: 8px;
      .basic-card-wrapper {
        margin-top: .75rem;
        cursor: pointer;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}


.selected-vehicle-details-open  {
  bottom: 50px;
  display: flex;
  left: 50px;
  flex-direction: column;
  animation-duration: 0.5s;
  animation-name: slideOut;
}


.details_window{
  bottom: 2.5rem;
  left: 2rem;
  display: flex;
  position: absolute;
  z-index: 99;
  width: 350px;
  height: 14rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.custom-size{
  width: 60%;
  line-height: 1rem;
}

.closed {
display:none
}


@media (max-width: 960px){
  .map-component{
    .search-input-field{
      padding: 0.2rem 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .map-component {
    margin: 0;
    padding-top: 0;
    .search-input-field{
      padding: 0.5rem 2rem !important;
    }

    .search-container {
      height: calc(100% - 2.4rem);
      position: absolute;
      z-index: 99;
      top: 1.2rem;
      right: 0;
    }

    .pb-24{
      padding-bottom: 0;
    }
  }
  .mobile {
    border-radius: 12px 12px 0px 0px;
    padding:0;
    left: 0;
    bottom: 0;
    width: 100%!important;
  }
  .custom-size {
    width: 100%!important;
  }
  .vehicle-details-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding:0px;
    margin: 0px;
  }

  .vehicle-details-vin{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding:0px;
    margin: 0px;
  }
  .ditails-window-position {
    position: unset!important;
  }

  .selected-vehicle-details-open {

    animation-name: slideOut_mobile;
  }

  .slide-in{

    animation-name: slideIn_mobile!important;
  }
}

.selected-vehicle-details-toggle {
  cursor: pointer;
}


.slide-in{
  animation-duration: 0.5s;
  animation-name: slideIn;
}



@keyframes slideIn {
  from {
    bottom:-200px;
  }

  to {
    bottom: 50px;
  }
}

@keyframes slideOut {
  from {
    bottom:-200px;
  }

  to {
    bottom: 50px;
  }
}

@keyframes slideOut_mobile {
  from {
    bottom:-200px;
  }

  to {
    bottom: 0px;
  }
}

@keyframes slideIn_mobile {
  from {
    bottom:-200px;
  }

  to {
    bottom: 0px;
  }
}


.close {
  cursor: pointer;
}
.view_more a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #AAAAAA;
}

.view_more::hover{
  color:  #AAAAAA;
  cursor: pointer;
}


.vehicle-details-text {
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 5px
  // padding:20px 0px 24px 24px;
}



.z-i95{
  z-index: 95!important;
}

