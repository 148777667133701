.vehicle-details-map-div{
    min-height: 200px;
    position: relative;
}

.vehicle-details-map {
    width: 100%;
}

#loading-for-map {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(250,250,250, 0.8);
    opacity: 0.8;
    align-items:center!important;
    justify-content: center!important;

}

#loading-for-map .loading-screen {
    width: 100%!important;
    height: 100%!important;
    align-items:center!important;
    justify-content: center!important;
}
