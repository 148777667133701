textarea:focus, textarea:active, input:focus, input:active {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.scroll {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 6rem;
  scrollbar-width:none;

}

.scroll::-webkit-scrollbar {
  display: none;
}


// Handle height with flexbox
.flex-1-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;

  &.row-container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .flex-col {
    overflow-y: auto;
  }
}

.flex-1-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 767px) {
  .flex-1-container {
    &.sm-column-container {
      flex-direction: column !important;
      justify-content: unset !important;
      flex-wrap: wrap !important;
    }
  }
}
